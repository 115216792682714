/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'react-toastify/dist/ReactToastify.css';


/* Custom global styles */
body {
  @apply font-sans antialiased;
  scroll-behavior: smooth; /* Enables smooth scrolling */
}

/* Scrollbar customization (optional) */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
